export const tagsMap = {
    "самопознание": "geekblue",
    "тревожность": "volcano",
    "депрессия": "blue",
    "личностныйРост": "orange",
    "подростки": "purple",
    "взрослые": "red",
    "пары": "cyan",
    "семейные отношения": "magenta",
    "детско-родительские отношения": "lime",
    "тревога": "gold",
    "психосоматические симптомы": "lime",
    "тревожные расстройства": "red",
    "стресс": "green",
    "эмоциональная регуляция": "geekblue",
    "межличностные отношения": "cyan",
    "смысл жизни": "volcano",
    "личностные кризисы": "volcano",
    "творческая самореализация": "gold",
    "интимные проблемы": "magenta",
    "сексологическая терапия": "magenta",
    "работа с детьми": "lime",
    "подростковая терапия": "purple"
}

export const items =  [
    {
        "id": 0,
        "name": "Ирина Алферова",
        "category": "Психолог, гештальт-терапевт, супервизор",
        "price": "3000",
        "location": "Москва",
        "format": ["онлайн", "офлайн"],
        "avatar": "/images/irina_alferova.jpg",
        "tags": ["подростки", "взрослые"],
        "shortAbout": "Психолог, аккредитованный гештальт-терапевт авторской программы «Московский Гештальт Институт» Общества практикующих психологов «Гештальт-Подход», супервизор",
        "contacts": {
            "mobile": "+7(903)-551-54-77",
            "tg": "IrinaAlferova"
        },
        "methodology": "Центр практической психологии «Гештальт подход», психолог-консультант в гештальт-подходе",
        "effect": "Психолог, аккредитованный гештальт-терапевт авторской программы «Московский Гештальт Институт» Общества практикующих психологов «Гештальт-Подход», супервизор",
        "education": [
            {
                "year": "2009 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Российский Государственный Социальный Университет г. Москва"]
            },
            {
                "year": "2014 г.",
                "lines": ["Сертификат. Детско-родительское системное консультирование и консультирование детей с основами детской психиатрии", "Институт интегративной семейной терапии"]
            }
        ]
    },
    {
        "id": 1,
        "name": "Марианна Андреева",
        "category": "Психолог, семейный терапевт, консультант пар, гештальт-терапевт",
        "price": "3500",
        "location": "Санкт-Петербург",
        "format": ["онлайн", "офлайн"],
        "avatar": "/images/marianna_andreeva.jpg",
        "tags": ["пары", "семейные отношения"],
        "shortAbout": "Психолог, семейный терапевт, консультант пар, гештальт-терапевт авторской программы «Московский Гештальт Институт» Общества практикующих психологов «Гештальт-Подход»",
        "contacts": {
            "mobile": "+7(967)-025-10-56",
            "tg": "MariannaAndreeva"
        },
        "methodology": "Московская академия профессиональных компетенций, педагог-психолог",
        "effect": "Психолог, семейный терапевт, консультант пар, гештальт-терапевт авторской программы «Московский Гештальт Институт» Общества практикующих психологов «Гештальт-Подход»",
        "education": [
            {
                "year": "2010 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Московская академия профессиональных компетенций"]
            },
            {
                "year": "2016 г.",
                "lines": ["Сертификат. Гештальт-терапия", "Московский Гештальт Институт"]
            }
        ]
    },
    {
        "id": 2,
        "name": "Юлия Асаилова",
        "category": "Психолог, сертифицированный гештальт-терапевт",
        "price": "3200",
        "location": "Москва",
        "format": ["онлайн"],
        "avatar": "/images/yulia_asailova.jpg",
        "tags": ["детско-родительские отношения", "семейные отношения", "тревога", "психосоматические симптомы"],
        "shortAbout": "Психолог, сертифицированный гештальт-терапевт авторской программы «Московский Гештальт Институт» Общества практикующих психологов «Гештальт-Подход»",
        "contacts": {
            "mobile": "+7(965)-090-09-13",
            "tg": "YuliaAsailova"
        },
        "methodology": "Санкт-Петербургский университет культуры и искусств, социальный педагог",
        "effect": "Психолог, сертифицированный гештальт-терапевт авторской программы «Московский Гештальт Институт» Общества практикующих психологов «Гештальт-Подход»",
        "education": [
            {
                "year": "2009 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Санкт-Петербургский университет культуры и искусств"]
            },
            {
                "year": "2014 г.",
                "lines": ["Сертификат. Гештальт-терапия", "Московский Гештальт Институт"]
            }
        ]
    },
    {
        "id": 3,
        "name": "Антон Белов",
        "category": "Психолог, когнитивно-поведенческий терапевт",
        "price": "2800",
        "location": "Санкт-Петербург",
        "format": ["онлайн", "офлайн"],
        "avatar": "/images/anton_belov.jpg",
        "tags": ["тревожные расстройства", "депрессия"],
        "shortAbout": "Психолог, специализирующийся на когнитивно-поведенческой терапии, работа с тревожными расстройствами и депрессией",
        "contacts": {
            "mobile": "+7(925)-123-45-67",
            "tg": "AntonBelov"
        },
        "methodology": "Санкт-Петербургский государственный университет, когнитивно-поведенческая терапия",
        "effect": "Психолог, специализирующийся на когнитивно-поведенческой терапии, работа с тревожными расстройствами и депрессией",
        "education": [
            {
                "year": "2011 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Санкт-Петербургский государственный университет"]
            },
            {
                "year": "2017 г.",
                "lines": ["Сертификат. Когнитивно-поведенческая терапия", "Санкт-Петербургский государственный университет"]
            }
        ]
    },
    {
        "id": 4,
        "name": "Анна Бениаминова",
        "category": "Психолог, арт-терапевт",
        "price": "3000",
        "location": "Москва",
        "format": ["онлайн", "офлайн"],
        "avatar": "/images/anna_beniaminova.jpg",
        "tags": ["творческая самореализация", "стресс"],
        "shortAbout": "Психолог, арт-терапевт, работа с творческой самореализацией и стрессом",
        "contacts": {
            "mobile": "+7(916)-555-44-33",
            "tg": "AnnaBeniaminova"
        },
        "methodology": "Московский государственный институт культуры, арт-терапия",
        "effect": "Психолог, арт-терапевт, работа с творческой самореализацией и стрессом",
        "education": [
            {
                "year": "2014 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподав\n\nатель психологии", "Московский государственный институт культуры"]
            },
            {
                "year": "2020 г.",
                "lines": ["Сертификат. Арт-терапия", "Московский государственный институт культуры"]
            }
        ]
    },
    {
        "id": 5,
        "name": "Наталья Гузева",
        "category": "Психолог, экзистенциальный терапевт",
        "price": "3100",
        "location": "Казань",
        "format": ["онлайн"],
        "avatar": "/images/natalya_guzeva.jpg",
        "tags": ["личностные кризисы", "смысл жизни"],
        "shortAbout": "Психолог, экзистенциальный терапевт, работа с личностными кризисами и поиском смысла жизни",
        "contacts": {
            "mobile": "+7(912)-345-67-89",
            "tg": "NatalyaGuzeva"
        },
        "methodology": "Казанский федеральный университет, экзистенциальная терапия",
        "effect": "Психолог, экзистенциальный терапевт, работа с личностными кризисами и поиском смысла жизни",
        "education": [
            {
                "year": "2013 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Казанский федеральный университет"]
            },
            {
                "year": "2019 г.",
                "lines": ["Сертификат. Экзистенциальная терапия", "Казанский федеральный университет"]
            }
        ]
    },
    {
        "id": 6,
        "name": "Анна Гуттаковская",
        "category": "Психолог, арт-терапевт",
        "price": "3000",
        "location": "Москва",
        "format": ["онлайн", "офлайн"],
        "avatar": "/images/anna_guttakovskaya.jpg",
        "tags": ["творческая самореализация", "стресс"],
        "shortAbout": "Психолог, арт-терапевт, работа с творческой самореализацией и стрессом",
        "contacts": {
            "mobile": "+7(916)-555-44-33",
            "tg": "AnnaGuttakovskaya"
        },
        "methodology": "Московский государственный институт культуры, арт-терапия",
        "effect": "Психолог, арт-терапевт, работа с творческой самореализацией и стрессом",
        "education": [
            {
                "year": "2014 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Московский государственный институт культуры"]
            },
            {
                "year": "2020 г.",
                "lines": ["Сертификат. Арт-терапия", "Московский государственный институт культуры"]
            }
        ]
    },
    {
        "id": 7,
        "name": "Андрей Егиазаров",
        "category": "Психолог, гештальт-терапевт",
        "price": "3200",
        "location": "Москва",
        "format": ["онлайн"],
        "avatar": "/images/andrey_egiazarov.jpg",
        "tags": ["личностные кризисы", "самопознание"],
        "shortAbout": "Психолог, гештальт-терапевт, работа с личностными кризисами и самопознанием",
        "contacts": {
            "mobile": "+7(917)-666-77-22",
            "tg": "AndreyEgiazarov"
        },
        "methodology": "Московский гештальт институт, гештальт-терапия",
        "effect": "Психолог, гештальт-терапевт, работа с личностными кризисами и самопознанием",
        "education": [
            {
                "year": "2015 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Московский государственный университет"]
            },
            {
                "year": "2021 г.",
                "lines": ["Сертификат. Гештальт-терапия", "Московский гештальт институт"]
            }
        ]
    },
    {
        "id": 8,
        "name": "Виктория Ильясова",
        "category": "Психолог, детский терапевт",
        "price": "3100",
        "location": "Москва",
        "format": ["онлайн", "офлайн"],
        "avatar": "/images/viktoria_ilyasova.jpg",
        "tags": ["работа с детьми", "подростковая терапия"],
        "shortAbout": "Психолог, детский терапевт, работа с детьми и подростками",
        "contacts": {
            "mobile": "+7(918)-888-99-11",
            "tg": "ViktoriaIlyasova"
        },
        "methodology": "Московский государственный педагогический университет, детская терапия",
        "effect": "Психолог, детский терапевт, работа с детьми и подростками",
        "education": [
            {
                "year": "2016 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Московский государственный педагогический университет"]
            },
            {
                "year": "2022 г.",
                "lines": ["Сертификат. Детская терапия", "Московский государственный педагогический университет"]
            }
        ]
    },
    {
        "id": 9,
        "name": "Любава Кобылинская-Ващук",
        "category": "Психолог, сексолог",
        "price": "3500",
        "location": "Санкт-Петербург",
        "format": ["онлайн", "офлайн"],
        "avatar": "/images/lyubava_kobylinskaya_vashchuk.jpg",
        "tags": ["сексологическая терапия", "интимные проблемы"],
        "shortAbout": "Психолог, сексолог, работа с интимными проблемами и сексологическая терапия",
        "contacts": {
            "mobile": "+7(919)-111-22-33",
            "tg": "LyubavaKobylinskayaVashchuk"
        },
        "methodology": "Санкт-Петербургский государственный университет, сексология",
        "effect": "Психолог, сексолог, работа с интимными проблемами и сексологическая терапия",
        "education": [
            {
                "year": "2017 г.",
                "lines": ["Диплом. Высшее. Психолог. Преподаватель психологии", "Санкт-Петербургский государственный университет"]
            },
            {
                "year": "2023 г.",
                "lines": ["Сертификат. Сексология", "Санкт-Петербургский государственный университет"]
            }
        ]
    }
]
