import s from './Home.module.scss'
import {Button, Select, Tag} from "antd";
import {StarFilled, StarOutlined} from "@ant-design/icons";
import {items, tagsMap} from "../../utils";
import {useNavigate} from "react-router";
import {useState} from "react";


const Home = () => {
    const navigate = useNavigate()
    const favouritesStorage = localStorage.getItem('favourites')
    const favourites = favouritesStorage ? JSON.parse(favouritesStorage) : []
    const [filteredTag, setFilteredTag] = useState()

    const [favouritesState, setFavouritesState] = useState(favourites)
    const [showOnlyFavourites, setShowOnlyFavourites] = useState(false)

    const toggleFavourite = (index: number) => {
        if (favouritesState.includes(index)) {
            const newFavourites = favouritesState.filter((i: number) => i !== index)
            setFavouritesState(newFavourites)
            localStorage.setItem('favourites', JSON.stringify(newFavourites))
        } else {
            const newFavourites = [...favouritesState, index]
            setFavouritesState(newFavourites)
            localStorage.setItem('favourites', JSON.stringify(newFavourites))
        }
    }

    return <div className={s.wrapper}>
        <div className={s.bar}>
            <Select
                onChange={(value) => {
                    setFilteredTag(value)
                }}
                allowClear
                size="large"
                showSearch
                value={filteredTag}
                className={s.select}
                options={Object.keys(tagsMap).map(tag => ({ value: tag, label: tag }))}
                placeholder="Поиск"
            />
            <Button size="large" onClick={() => setShowOnlyFavourites(!showOnlyFavourites)}>
                <StarFilled className={s.star} size={40} />
            </Button>
        </div>
        <div className={s.content}>
            {items.filter((item) => {
                const hasTag = filteredTag ? item.tags.includes(filteredTag) : true
                if (showOnlyFavourites) {
                    return favouritesState.includes(item.id) && hasTag
                } else return hasTag
            }).map((item, index) =>
                <div className={s.card} key={item.id}>
                    <div className={s.top}>
                        <div className={s.avatar}
                             style={{backgroundImage: `url(${item.avatar})`}}></div>
                        <div className={s.info}>
                            {favouritesState.includes(item.id) ? <StarFilled className={s.star} size={40} onClick={() => toggleFavourite(item.id)}/> : <StarOutlined className={s.star} size={40} onClick={() => toggleFavourite(item.id)} />}
                            <div className={s.name}>{item.name}</div>
                            <div className={s.category}>{item.category}</div>
                            <div className={s.price}>от <b>{item.price}</b> рублей / час.</div>
                        </div>
                    </div>
                    <div className={s.center}>
                        <div className={s.helps}>Помогаю с:</div>
                        <div className={s.categories}>
                            {item.tags.map(tag => {
                                // @ts-ignore
                                return <Tag color={tagsMap[tag] ?? ''}>#{tag}</Tag>
                            })}
                        </div>
                        <div className={s.location}>
                            <b>Локация:</b> {item.location}
                        </div>
                        <div className={s.format}>
                            <b>Формат:</b> {item.format.join(', ')}
                        </div>
                        <div className={s.about}>
                            {item.shortAbout}
                        </div>
                        <Button type="primary" size="large" className={s.button}  onClick={() => navigate(`/details/${index}`)}>
                            Записаться
                        </Button>
                    </div>
                </div>)}
        </div>
    </div>
}

export default Home
