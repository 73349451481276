import s from './Details.module.scss'
import {Button, Tag} from "antd";
import {items, tagsMap} from "../../utils";
import {useNavigate, useParams} from "react-router";
import {StarFilled, StarOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";



const Details = () => {
    const favouritesStorage = localStorage.getItem('favourites')
    const favourites = favouritesStorage ? JSON.parse(favouritesStorage) : []

    const [favouritesState, setFavouritesState] = useState(favourites)

    const toggleFavourite = (index: number) => {
        if (favouritesState.includes(index)) {
            const newFavourites = favouritesState.filter((i: number) => i !== index)
            setFavouritesState(newFavourites)
            localStorage.setItem('favourites', JSON.stringify(newFavourites))
        } else {
            const newFavourites = [...favouritesState, index]
            setFavouritesState(newFavourites)
            localStorage.setItem('favourites', JSON.stringify(newFavourites))
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const params = useParams()
    const navigate = useNavigate()
    const item = items[Number(params?.index) ?? 0]
    return <div className={s.wrapper}>
        <Button className={s.back} size="large" onClick={() => navigate('/')}>Вернуться к поиску</Button>
        <div className={s.top}>
            <div className={s.avatar}
                 style={{backgroundImage: `url(${item.avatar})`}}></div>
            <div className={s.info}>
                {favouritesState.includes(item.id) ? <StarFilled className={s.star} size={40} onClick={() => toggleFavourite(item.id)}/> : <StarOutlined className={s.star} size={40} onClick={() => toggleFavourite(item.id)} />}
                <div className={s.name}>{item.name}</div>
                <div className={s.category}>{item.category}</div>
                <div className={s.price}>от <b>{item.price}</b> рублей / час.</div>
            </div>
        </div>
        <div className={s.center}>
            <div className={s.section}>
                <div className={s.title}>Локация:</div>
                <div className={s.value}>
                    {item.location}
                </div>
            </div>
            <div className={s.section}>
                <div className={s.title}>Формат работы:</div>
                <div className={s.value}>
                    {item.format.join(', ')}
                </div>
            </div>
            <div className={s.section}>
                <div className={s.title}>Контакты:</div>
                <div className={s.value}>
                    <div>моб. {item.contacts.mobile}</div>
                    <div>тг. <a href="">@{item.contacts.tg}</a></div>
                </div>
            </div>
            <div className={s.section}>
                <div className={s.title}>С чем работаю:</div>
                <div className={s.categories}>
                    {item.tags.map(tag => {
                        // @ts-ignore
                        return <Tag color={tagsMap[tag]}>#{tag}</Tag>
                    })}
                </div>
            </div>
            <div className={s.section}>
                <div className={s.title}>О методике работы:</div>
                <div className={s.value}>
                    {item.methodology}
                </div>
            </div>
            <div className={s.section}>
                <div className={s.title}>Информация:</div>
                <div className={s.value}>
                    {item.effect}
                </div>
            </div>
            <div className={s.section}>
                <div className={s.title}>Образование:</div>
                <div className={s.value}>
                    {item.education.map((row) => <>
                        <p>{row.year}</p>
                        <ul>
                            {row.lines.map(line => <li>{line}</li>)}
                        </ul>
                    </>)}
                </div>
            </div>
        </div>
    </div>
}

export default Details
