import React from 'react';
import {Route, Routes} from "react-router";
import Home from "./pages/home";
import Details from "./pages/details";

function App() {
  return (
      <Routes>
        <Route path="/" element={<Home />} />
          <Route path="/details/:index" element={<Details />} />
      </Routes>
  );
}

export default App;
